<template>
  <!-- begin::SubscriptionTypes edit -->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("SUBSCRIPTION_TYPES.TITLE_EDIT") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("SUBSCRIPTION_TYPES.SUBTITLE_DATA") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("SUBSCRIPTION_TYPES.PRICE_PER_MONTH") }}
            </label>
            <v-text-field
              v-if="isSenderDefault"
              class="form-control shortInput"
              value="0"
              :title="$t('SUBSCRIPTION_TYPES.PRICE_SENDER_DEFAULT_FREE')"
              readonly
            />
            <v-text-field
              v-else
              class="form-control shortInput"
              v-model="Fields.PricePerMonth"
              :placeholder="$t('SUBSCRIPTION_TYPES.PRICE_PER_MONTH')"
              type="number"
              min="0"
              step="0.01"
              :rules="[rules.requiredMin0]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("SUBSCRIPTION_TYPES.MONTHS") }}
            </label>
            <v-text-field
              class="form-control shortInput"
              v-model="Fields.Months"
              :placeholder="$t('SUBSCRIPTION_TYPES.MONTHS')"
              type="number"
              min="1"
              step="1"
              :rules="[rules.requiredMin1]"
            />
          </div>
        </div>

        <div class="row form-group m-0">
          <label>
            {{ $t("SUBSCRIPTION_TYPES.NAME") }}
          </label>
        </div>
        <div class="row">
          <div
            v-for="aLang in LanguageList"
            :key="'Name_' + aLang.lang"
            class="form-group col-lg-4"
          >
            <label>
              {{ aLang.name }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Name[aLang.lang]"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="manager-subtitle">
          {{ $t("SUBSCRIPTION_TYPES.SUBTITLE_DESCRIPTION") }}
        </div>

        <div
          v-for="i in DescriptionItems"
          :key="'Line_' + i"
          class="row form-group"
        >
          <label class="col-lg-12">
            {{ $t("SUBSCRIPTION_TYPES.DESCRIPTION") + " " + i }}
          </label>
          <div
            v-for="aLang in LanguageList"
            :key="'Line_' + i + '_' + aLang.lang"
            class="col-lg-12 form-group mb-2"
          >
            <label>
              {{ aLang.name }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Description[aLang.lang][i - 1]"
            />
          </div>
        </div>

        <div class="p-3">
          <button
            class="btn btn-pill button-filter btn-primary mb-3"
            @click.prevent="addDescriptionItem"
          >
            <inline-svg src="/media/icons/add.svg" class="mr-3" />
            {{ $t("SUBSCRIPTION_TYPES.ADD_DESCRIPTION") }}
          </button>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/subscriptions/types')"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::SubscriptionTypes edit -->
</template>

<script>
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";

export default {
  name: "SubscriptionTypesEdit",
  data() {
    return {
      SubscriptionTypeID: 0,
      CompanyTypeID: 0,
      IsDefault: true,
      Fields: {
        Name: {},
        Description: {},
        PricePerMonth: 0,
        Months: 1
      },
      Valid: true,
      LanguageList: i18nService.languages,
      DescriptionItems: 0,
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        requiredMin0: value => {
          let value2 = parseInt(value);
          if (isNaN(value2) || value2 < 0) {
            return this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 });
          } else {
            return true;
          }
        },
        requiredMin1: value => {
          let value2 = parseInt(value);
          if (isNaN(value2) || value2 < 1) {
            return this.$i18n.t("VALIDATION.MIN_VALUE", { min: 1 });
          } else {
            return true;
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters(["companyTypesList"]),
    isSenderDefault() {
      return (
        this.CompanyTypeID == this.companyTypesList.ID_SENDER && this.IsDefault
      );
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.SubscriptionTypeID = parseInt(this.$route.params.id);
      this.loadSubscriptionData();
    } else {
      this.$router.push("/manager/subscriptions/types");
    }
  },

  methods: {
    loadSubscriptionData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      SubscriptionTypeService.getProfile(this.SubscriptionTypeID).then(
        response => {
          if (response === 404) {
            this.$router.push("/manager/subscriptions/types");
          } else {
            this.CompanyTypeID = response.CompanyTypeID;
            this.IsDefault = response.IsDefault;
            this.Fields.PricePerMonth = response.PricePerMonth;
            this.Fields.Months = response.Months;
            this.Fields.Name = response.Name;
            this.Fields.Description = response.Description;

            // Count lines of Description on most-filled language
            this.DescriptionItems = 0;
            this.LanguageList.forEach(aLang => {
              let langCode = aLang.lang;
              let langItems = response.Description[langCode]
                ? response.Description[langCode].length
                : 0;

              if (this.DescriptionItems < langItems) {
                this.DescriptionItems = langItems;
              }
            });
            this.padDescriptionItems();
          }

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      );
    },

    padDescriptionItems() {
      // Ensure all languages have the same amount of Description lines
      this.LanguageList.forEach(aLang => {
        let langCode = aLang.lang;
        if (!this.Fields.Description[langCode]) {
          this.Fields.Description[langCode] = [];
        }

        while (
          this.Fields.Description[langCode].length < this.DescriptionItems
        ) {
          this.Fields.Description[langCode].push("");
        }
      });
    },

    addDescriptionItem(e) {
      e.preventDefault();
      this.DescriptionItems = this.DescriptionItems + 1;
      this.padDescriptionItems();
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        SubscriptionTypeService.updateProfile(
          this.SubscriptionTypeID,
          this.Fields
        ).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              solid: true
            });
          }

          // Reset submit button
          this.Valid = true;
        });
      }
    }
  }
};
</script>
